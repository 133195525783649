<template>
  <div class="page">
    <div class="border-radius4 height50"
        style="background: #FAFAFA;display: flex;">
      <div style="background: #0768FD;color: white;display: flex; align-items: center;justify-content: center;cursor: pointer;"
           class="width110 font-size16 border-radius4004"
           @click="goBack">
        返回
      </div>
      <div style="color: black;font-weight: bold;display: flex; align-items: center;" class="font-size16 margin-left30">
        商户信息
      </div>
    </div>
    <div class="content-box section">
      <easy-card title="基本信息">
<!--        <icon-button slot="extra" icon="return" @click.native="goBack"/>-->
        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 注册账号</template>
            {{ detailInfo.id }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 商户名称</template>
            {{ detailInfo.name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 身份证号码</template>
            {{ detailInfo.certificateNumber }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"></template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 身份证人像面</template>
            <PictureMerchant :attachId="detailInfo.frontOfIdCard" width="240px"/>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 身份证国徽面</template>
            <PictureMerchant :attachId="detailInfo.backOfIdCard" width="240px"/>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 营业执照</template>
            <PictureMerchant :attachId="detailInfo.licensePhoto" width="240px"/>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 店铺照片</template>
            <PictureMerchant :attachId="detailInfo.officePhoto" width="240px"/>
            <!-- <el-image
              style="width: 100px; height: 100px"
              :src="detailInfo.merchantPhoto"
              :fit="fit"
            ></el-image> -->
          </el-descriptions-item>
        </el-descriptions>
      </easy-card>
      <!-- <div style="text-align: right;">
        <el-button type="success" @click="leaseOrderVerify(0)"
            >通过</el-button
          >
          <el-button type="danger" @click="leaseOrderVerify(2)">拒绝</el-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import {selectCommercialTenantParticulars} from "@/api/user";
import PictureMerchant from "@/components/PictureMerchant";
import {
  checkToken,
} from "@/api/common";
import {MessageBox} from "element-ui";

export default {
  components: {
    PictureMerchant,
  },
  data() {
    return {
      detailInfo:{},
    };
  },
  methods: {
    //返回商户管理列表页
    goBack() {
      this.$router.back();
    },
    //查询商户信息详情
    getDetail(id) {
      selectCommercialTenantParticulars(id).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.detailInfo = res.returnObject
        }
      });
    },
  },
  mounted() {
    checkToken()
        .then(res => {
          console.log('checkToken', res)
          if (!res || res.code !== 30) {
            MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
              confirmButtonText: '重新登录',
              callback: () => {
                window.localStorage.clear()
                window.$vue.$router.push('/login')
              }
            });
          } else {
            const id = this.$route.query.id;
            this.getDetail(id);
          }
        })
  },
};
</script>

<style lang="less" scoped>
</style>
